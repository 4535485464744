import { useAnchorWallet, useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import * as anchor from '@project-serum/anchor'
import clubProgramIdl from "../idl/solclub_program_base.json"
import { ASSOCIATED_TOKEN_PROGRAM_ID, createAssociatedTokenAccountInstruction, getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { LAMPORTS_PER_SOL, PublicKey, SystemProgram, Transaction, TransactionInstruction } from '@solana/web3.js'
import axios from 'axios'
import { programIds } from '../utils/ids'
import { showProgressToast } from '../utils/toast'
import { BaseContext } from './BaseProvider'
import { useSelector } from 'react-redux'
import { confirmTransactionWithRetry } from '../utils/txnRetryLogic'
import TokenData from '../data/token_data'


export const ClubBaseContext = createContext()

const raffleProgramKey = new PublicKey(process.env.NEXT_PUBLIC_SOLCLUB_ID);
const raffleFeeOwner = new PublicKey("4mivnMu9ooY83gmMCu6Yqbdwg2EKwBwNAvKMLbwTaf3z")

export default function ClubBaseProvider({children}) {
    const {connection} = useConnection()
    const wallet = useWallet()
    const anchorWallet = useAnchorWallet()
    const {getWalletBalances} = useContext(BaseContext)
    const [txnId, setTxnId] = useState(null)
    const [allRaffles, setAllRaffles] = useState(null)
    const [allAuctions, setAllAuctions] = useState(null)
    const [raffleStatsAccount, setRaffleStatsAccount] = useState(null)
    const [raffleStats, setRaffleStats] = useState(null)
    const [isProgramLoaded, setIsProgramLoaded] = useState(false)
    const [authorityWallet, setAuthorityWallet] = useState(null)

    const [raffleSettings, setRaffleSettings] = useState({
      raffleSettingsAccount: '',
      raffleStatsAccount: ''
    })

    const RAFFLE_PDA_SEED = Buffer.from(
      anchor.utils.bytes.utf8.encode("solclub-raffles")
    )
    const AUCTION_PDA_SEED = Buffer.from(
      anchor.utils.bytes.utf8.encode("solclub-auctions")
    )

    const defaultToken = TokenData[1]

    const { projectData } = useSelector((state) => state.counter);

    useEffect(() => {

      if(projectData){
        setRaffleStatsAccount(new PublicKey(projectData.programKey))
      }
    }, [projectData])
    

    const solclub = useMemo(() => {
        const provider = new anchor.AnchorProvider(connection, anchorWallet, anchor.AnchorProvider.defaultOptions())
        return new anchor.Program(clubProgramIdl, raffleProgramKey, provider)
    }, [connection, anchorWallet])


    useEffect(() => {
      ;(async()=> {
        if(solclub && raffleStatsAccount){
          const statsAccountData = await solclub.account.raffleStatsAccount.fetch(raffleStatsAccount)
          const settingsAccount = statsAccountData.raffleSettingAccount
          console.log("statsAccountData", statsAccountData)
          if(settingsAccount){
            setAuthorityWallet(statsAccountData.raffleAuthority)
            setRaffleSettings({
              raffleSettingsAccount: settingsAccount,
              raffleStatsAccount: raffleStatsAccount,
            })
          }
          setIsProgramLoaded(true)

          const tokenData = TokenData
          for (let i = 1; i < tokenData.length; i++) {
            const index = statsAccountData.totalTokenUsedList.findIndex(x => x.tokenKey.toString() === tokenData[i].mintKey)
            if(index > -1){
              tokenData[i].amountUsed =  statsAccountData.totalTokenUsedList[index].amountUsed
              
            }
          }
          setRaffleStats({
            tokenData: tokenData,
            finishedRaffles: statsAccountData.finishedRaffles,
            openRaffles: statsAccountData.openRaffles
          })
        }


      })()
    }, [solclub, raffleStatsAccount])


    useEffect(() => {
      ;(async()=>{
        if(solclub && !allRaffles && raffleSettings.raffleSettingsAccount){
          await getAllRaffles()
          await getAllAuctions()
        } 
      })()
    }, [solclub, allRaffles, raffleSettings.raffleSettingsAccount])
    


    

    const getAllRaffles = async () => { 
      
      const raffleAllAccounts = await solclub.account.raffleData.all([
        {
          memcmp: {
              offset: 8+1+1+1+1,
              bytes: raffleSettings.raffleSettingsAccount.toBase58(), 
          }
        }
      ])

      // console.log(raffleAllAccounts.map(x => {
      //   return {
      //     itemVault: x.account.itemVault.toString()
      //   }
      // }))
      setAllRaffles(raffleAllAccounts.map((raffle)=> {
       return {...raffle.account, accountKey: raffle.publicKey}
      }))
    }


    const getAllAuctions = async () => { 
      
      const auctionAllAccounts = await solclub.account.auctionData.all([
        {
          memcmp: {
              offset: 8+1+1,
              bytes: raffleSettings.raffleStatsAccount.toBase58(), 
          }
        }
      ])

      // console.log(raffleAllAccounts.map(x => {
      //   return {
      //     itemVault: x.account.itemVault.toString()
      //   }
      // }))
      setAllAuctions(auctionAllAccounts.map((auction)=> {
       return {...auction.account, accountKey: auction.publicKey}
      }))
    }

    const getSingleRaffles = async (key) => { 
      const raffleKey = new PublicKey(key)
      const raffleAccount = await solclub.account.raffleData.fetch(raffleKey)
      console.log(raffleAccount)
      //setAllRaffles(raffleAllAccounts.map((raffle)=> {
       return raffleAccount
    }

    const getSingleAuctions = async (key) => { 
      const auctionKey = new PublicKey(key)
      const auctionAccount = await solclub.account.auctionData.fetch(auctionKey)
      console.log(auctionAccount)
      //setAllRaffles(auctionAllAccounts.map((auction)=> {
       return auctionAccount
    }


    // useEffect(() => {
    //   console.log(raffleSettings)
    // }, [raffleSettings])
    const getRaffleTransactions = async (key) => { 
      const accountKey = new PublicKey(key)
      let txns = []
      let users = []

      const raffleSettingsAccount = raffleSettings?.raffleSettingsAccount
      const [rafflePDA] = await PublicKey.findProgramAddress(
        [ RAFFLE_PDA_SEED, 
          raffleSettingsAccount.toBuffer(),
          accountKey.toBuffer(),
        ],
        raffleProgramKey
      )

      const signatures = await connection.getSignaturesForAddress(
        accountKey, {}, "finalized")
  
        //console.log(signatures[0])
        const transactions = await Promise.all(
          signatures.map(({ signature }) =>
          connection.getTransaction(signature, {commitment: "finalized"})
          )
        )
        const raffleTransactions = transactions.filter(txn => 
          (txn.meta.logMessages.join(" ").includes("Instruction: EnterOfficialRaffle") && txn.meta.logMessages.join(" ").includes("Instruction: Transfer"))
          )
          const raffleSolTransactions = transactions.filter(txn => 
            (txn.meta.logMessages.join(" ").includes("Instruction: EnterOfficialSolRaffle"))
            )  
        // console.log("raffleTransactions",raffleTransactions)
        let txnList = raffleTransactions.length > 0 ? raffleTransactions : raffleSolTransactions.length > 0 ? raffleSolTransactions : []
        for (const txn of txnList) {
          let raffles
          let owner
          if(raffleTransactions.length > 0){
            const preownerData = txn.meta.preTokenBalances.filter(x => x.owner.toString() !== rafflePDA.toString())[0]
            const postownerData =  txn.meta.postTokenBalances.find(x => x.owner.toString() === preownerData.owner.toString())
            //console.log(ownerData)
            raffles = preownerData.uiTokenAmount.uiAmount - postownerData.uiTokenAmount.uiAmount
            owner = preownerData.owner.toString()
          }else{
            const reduced = txn.meta.preBalances.reduce((acc, item, index) => {
              const val = item - txn.meta.postBalances[index]
              if(val > 0){
                acc.owner = txn.transaction.message.accountKeys[index].toString()
                acc.raffles = val / LAMPORTS_PER_SOL
              }
              return acc
            }, {raffles: 0, owner: ""})
            
            raffles = reduced.raffles
            owner = reduced.owner
          }

          //console.log("raffles & owner",raffles,owner)
          if(owner != rafflePDA.toString()){
            txns.push({
              wallet: owner,
              amount: raffles,
              time: txn.blockTime,
              txid: txn.transaction.signatures[0]
            })
            const userIndex = users.findIndex(x => x.wallet === owner)
            if(userIndex > -1){
              users[userIndex].amount += raffles
            }else{
              users.push({
                wallet: owner,
                amount: raffles
              })
            }
          }
        }
        users.sort((a,b)=> b.amount - a.amount)
        return {txns, users}
     }

     const getAuctionTransactions = async (key, authority, isSol) => { 
      const accountKey = new PublicKey(key)
      let txns = []

      let [auctionPda, auctionPdaNonce] = await anchor.web3.PublicKey.findProgramAddress(
        [
          AUCTION_PDA_SEED,
          authority.toBuffer(),
          accountKey.toBuffer(),
        ],
        solclub.programId,
      );

      console.log(auctionPda.toString())


      const signatures = await connection.getSignaturesForAddress(
        accountKey, {}, "finalized")
  
        //console.log(signatures[0])
        const transactions = await Promise.all(
          signatures.map(({ signature }) =>
          connection.getTransaction(signature, {commitment: "finalized"})
          )
        )
        const auctionTransactions = transactions.filter(txn => txn.meta.logMessages.join(" ").includes("Instruction: BidAuction"))
        console.log("auctionTransactions",auctionTransactions)
        for (const txn of auctionTransactions) {

          if(!isSol){
          const preownerData = txn.meta.preTokenBalances.filter(x => x.owner.toString() !== auctionPda.toString())
          console.log(preownerData)
          let preAmount = 0
          let postAmount = 0
          let owner = ''
          if(preownerData.length === 1){
            const postownerData =  txn.meta.postTokenBalances.find(x => x.owner.toString() === preownerData[0].owner.toString())
            preAmount = preownerData[0].uiTokenAmount.uiAmount
            postAmount = postownerData.uiTokenAmount.uiAmount
            owner = preownerData[0].owner.toString()
          }
          if(preownerData.length > 1){
            const result = preownerData.reduce((acc, item, index) => {
              const post = txn.meta.postTokenBalances.find(x => x.owner.toString() === item.owner.toString())
              if(item.uiTokenAmount.uiAmount - post.uiTokenAmount.uiAmount > 0){
                acc.post = post.uiTokenAmount.uiAmount
                acc.pre = item.uiTokenAmount.uiAmount
                acc.owner = item.owner.toString()
              }
              return acc
            }, {pre: 0, post: 0, owner: ''})

            preAmount = result.pre
            postAmount = result.post
            owner = result.owner
          }

          //console.log(ownerData)
          const bidamount = preAmount - postAmount

          //console.log("raffles & owner",raffles,owner)
            txns.push({
              wallet: owner,
              amount: bidamount,
              time: txn.blockTime,
              txid: txn.transaction.signatures[0]
            })
          }else{
            const result = txn.meta.preBalances.reduce((acc, item, index) => {
              if(item !== txn.meta.postBalances[index] && item - txn.meta.postBalances[index] > 0){
                acc.post = txn.meta.postBalances[index]
                acc.pre = item
                acc.owner = txn.transaction.message.accountKeys[index].toString()
              }
              return acc
            }, {pre: 0, post: 0, owner: ''})

            txns.push({
              wallet: result.owner,
              amount: (result.pre - result.post)/LAMPORTS_PER_SOL,
              time: txn.blockTime,
              txid: txn.transaction.signatures[0]
            })
          }

        }
        return {txns}
     }

    const handleCreateRaffleOfficial = async (raffleData, toastId) => { 
      console.log(raffleData)
      const isOnChain = raffleData.isOnChain ? 1 : 0
      const raffleType = parseInt(raffleData.raffleType) + 1
      const maxEntries =  new anchor.BN(raffleData.maxEntries)
      const numTokenPerEntry =  new anchor.BN(raffleData.amountPerEntry * raffleData.tokenLamports)
      const winners = raffleData.totalWinners
      const endDuration = new anchor.BN((raffleData.raffleEndTime.getTime() - raffleData.raffleStartTime.getTime())/60000)
      const rewardMintKey = new PublicKey(raffleData.itemMint)
      const raffleTokenMintKey = new PublicKey(raffleData.tokenKey)
      const isSol = raffleData.isSol
      //rtype = 1 nft raffle & 2 for spl-token
      //winners = number of winners
      //raffleType = normal or premium
      showProgressToast(toastId, "Initiating Raffle: Gathering all accounts...", false)
      // Raffle Stats Account
      const raffleDataAccount = anchor.web3.Keypair.generate()

      if(raffleData.isOnChain){
        showProgressToast(toastId, "Creating Raffle: Getting accounts for On-Chain item...")
        // Item Account owned by Raffler
        console.log(rewardMintKey)
        const rewardAccount = await getAssociatedTokenAddress(
          rewardMintKey,
          wallet.publicKey,
        )
        const rewardAccountInfo = await connection.getAccountInfo(
          rewardAccount
        )
        if (rewardAccountInfo == null) {
          return { result: false, error: "Reward Key Wrong or Not found in the wallet" };
        }

        let instructions = []

        try {

          //PDA owning all vault accounts
         const [rafflePDA] = await PublicKey.findProgramAddress(
             [ RAFFLE_PDA_SEED, 
               raffleSettings.raffleSettingsAccount.toBuffer(),
               raffleDataAccount.publicKey.toBuffer(),
             ],
             raffleProgramKey
           )

          // console.log(raffleProgramKey.toString(), rafflePDA.toString())
           //raffle token vault on chain
           const raffleTokenVault = await getAssociatedTokenAddress(
            raffleTokenMintKey,
            rafflePDA,
            true
          )
          const raffleTokenVaultInfo = await connection.getAccountInfo(
            raffleTokenVault
          )
      
          if (raffleTokenVaultInfo == null) {
            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              raffleTokenVault,
              rafflePDA,
              raffleTokenMintKey
            )
            instructions.push(Ix)
          }

          //Reward item vault on chain
          const itemVault = await getAssociatedTokenAddress(
            rewardMintKey,
            rafflePDA,
            true
          )
      
          const itemVaultInfo = await connection.getAccountInfo(
            itemVault
          )
      
          if (itemVaultInfo == null) {
            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              itemVault,
              rafflePDA,
              rewardMintKey
            )
      
            instructions.push(Ix)
          }
          showProgressToast(toastId, "Creating Raffle: Initiating the Txn...")


          const tx = solclub.transaction.createOfficialRaffle(
            endDuration,
            raffleType,
            isOnChain,
            maxEntries,
            numTokenPerEntry,
            winners,
            isSol,
            {
              accounts:{
                raffleData: raffleDataAccount.publicKey,
                raffleSettingAccount: raffleSettings.raffleSettingsAccount,
                raffleStatsAccount: raffleSettings.raffleStatsAccount,
                itemAccount: rewardAccount,
                itemMint: rewardMintKey,
                itemVault: itemVault,
                rafflePdaAccount: rafflePDA,
                raffleTokenVault: raffleTokenVault,
                raffleTokenMint: raffleTokenMintKey,
                raffleAuthority: wallet.publicKey,
                tokenProgram: TOKEN_PROGRAM_ID,
                tokenMetadataProgram: programIds().metadata,
                systemProgram: SystemProgram.programId,
                clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
              },
              signers: [raffleDataAccount]
            }
          )

          
          
          // ---- signing txn on backend server ---
          // txAll.recentBlockhash = latestBlockhash.blockhash;
          // txAll.feePayer = wallet.publicKey
          // txAll.partialSign(raffleDataAccount)
          // const signedTx = await wallet.signTransaction(txAll)
          // const wireTransaction = signedTx.serialize();
          // const base64 = wireTransaction.toString('base64')
          // const data = {
          //   tx: base64
          // }
          // console.log(data)
          // const response = await axios.post("/api/sendTransaction", data)
          // console.log(response)
          // ---- signing txn on backend server --- 


          showProgressToast(toastId, "Creating Raffle: Waiting for Signature...")

        // const blockhashResponse = await connection.getLatestBlockhashAndContext();

         const txAll = new Transaction().add(...instructions, tx);
        //  txAll.lastValidBlockHeight = blockhashResponse.value.lastValidBlockHeight;
        //  txAll.recentBlockhash = blockhashResponse.value.blockhash;
        //  txAll.feePayer = wallet.publicKey
        //  txAll.partialSign(raffleDataAccount)
        //  const signedTx = await wallet.signTransaction(txAll)
        //  const rawTransaction = signedTx.serialize();
        //  const lastValidBlockHeight = blockhashResponse.context.slot + 150;

        //  let blockheight = await connection.getBlockHeight();

          //let txid
          
//           while (blockheight < lastValidBlockHeight) {
//             txid = connection.sendRawTransaction(rawTransaction, {
//               skipPreflight: false,
//             });
//             setTxnId(txid)
//             await sleep(500);
//             await connection.confirmTransaction(txid, 'processed')
//             blockheight = await connection.getBlockHeight();
//           }

// console.log(txid)
          const signature = await wallet.sendTransaction(txAll, connection, {
              signers: [raffleDataAccount],
              skipPreflight: false,
              preflightCommitment: "confirmed",
            })
            showProgressToast(toastId, "Creating Raffle: Waiting for Confirmation of txn...")
            setTxnId(signature)

            return successHandle(toastId, signature, "Transaction Confirmed, Raffle Created! ✅", raffleDataAccount.publicKey.toString(), false)
         // await getAllRaffles()
        } catch (err) {
          return errorHandleExtra(toastId, err, raffleDataAccount.publicKey.toString())
        }
      }else{
        return { result: false, error: "wrong option"}
      }
    }

    const handleCreateOfflineRaffleOfficial = async (raffleData, toastId) => { 
      console.log(raffleData)
      const isOnChain = 0
      const raffleType = parseInt(raffleData.raffleType) + 1
      const maxEntries =  new anchor.BN(raffleData.maxEntries)
      const numTokenPerEntry =  new anchor.BN(raffleData.amountPerEntry * raffleData.tokenLamports)
      const winners = raffleData.totalWinners
      const endDuration = new anchor.BN((raffleData.raffleEndTime.getTime() - raffleData.raffleStartTime.getTime())/60000)
      const raffleTokenMintKey = new PublicKey(raffleData.tokenKey)
      const isSol = raffleData.isSol
      //rtype = 1 nft raffle & 2 for spl-token
      //winners = number of winners
      //raffleType = normal or premium
      showProgressToast(toastId, "Initiating Raffle: Gathering all accounts...", false)
      // Raffle Stats Account
      const raffleDataAccount = anchor.web3.Keypair.generate()

      if(!raffleData.isOnChain){
        showProgressToast(toastId, "Creating Raffle: Getting accounts for On-Chain item...")
        // Item Account owned by Raffler


        let instructions = []

        try {

          //PDA owning all vault accounts
         const [rafflePDA] = await PublicKey.findProgramAddress(
             [ RAFFLE_PDA_SEED, 
               raffleSettings.raffleSettingsAccount.toBuffer(),
               raffleDataAccount.publicKey.toBuffer(),
             ],
             raffleProgramKey
           )

           console.log(raffleProgramKey.toString(), rafflePDA.toString())
           //raffle token vault on chain
           const raffleTokenVault = await getAssociatedTokenAddress(
            raffleTokenMintKey,
            rafflePDA,
            true
          )
          const raffleTokenVaultInfo = await connection.getAccountInfo(
            raffleTokenVault
          )
      
          if (raffleTokenVaultInfo == null) {
            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              raffleTokenVault,
              rafflePDA,
              raffleTokenMintKey
            )
            instructions.push(Ix)
          }

 
          showProgressToast(toastId, "Creating Raffle: Initiating the Txn...")


          // console.log({
          //   raffleData: raffleDataAccount.publicKey,
          //   raffleSettingAccount: raffleSettings.raffleSettingsAccount,
          //   raffleStatsAccount: raffleSettings.raffleStatsAccount,
          //   rafflePdaAccount: rafflePDA,
          //   raffleTokenVault: raffleTokenVault,
          //   raffleTokenMint: raffleTokenMintKey,
          //   raffleAuthority: wallet.publicKey,
          //   tokenProgram: TOKEN_PROGRAM_ID,
          //   tokenMetadataProgram: programIds().metadata,
          //   systemProgram: SystemProgram.programId,
          //   clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
          // })

          // console.log(endDuration,
          //   raffleType,
          //   isOnChain,
          //   maxEntries,
          //   numTokenPerEntry,
          //   winners)

          const tx = solclub.transaction.createOfficialOffchainRaffle(
            endDuration,
            raffleType,
            isOnChain,
            maxEntries,
            numTokenPerEntry,
            winners,
            isSol,
            {
              accounts:{
                raffleData: raffleDataAccount.publicKey,
                raffleSettingAccount: raffleSettings.raffleSettingsAccount,
                raffleStatsAccount: raffleSettings.raffleStatsAccount,
                rafflePdaAccount: rafflePDA,
                raffleTokenVault: raffleTokenVault,
                raffleTokenMint: raffleTokenMintKey,
                raffleAuthority: wallet.publicKey,
                tokenProgram: TOKEN_PROGRAM_ID,
                tokenMetadataProgram: programIds().metadata,
                systemProgram: SystemProgram.programId,
                clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
              },
              signers: [raffleDataAccount]
            }
          )

              

          showProgressToast(toastId, "Creating Raffle: Waiting for Signature...")


         const txAll = new Transaction().add(...instructions, tx);


          const signature = await wallet.sendTransaction(txAll, connection, {
              signers: [raffleDataAccount],
              skipPreflight: false,
              preflightCommitment: "confirmed",
            })
            showProgressToast(toastId, "Creating Raffle: Waiting for Confirmation of txn...")
            setTxnId(signature)
            return successHandle(toastId, signature, "Transaction Confirmed, Raffle Created! ✅", raffleDataAccount.publicKey.toString(), false)
        } catch (err) {
            return errorHandleExtra(toastId, err, raffleDataAccount.publicKey.toString())
        }

      }else{
        return { result: false, error: "wrong option"}
      }
 }


    const handleBuyRaffle = async (raffleData, tickets, tokenBalance, toastId) => { 
      const ticketsUsed = parseInt(tickets)

      const raffleDataKey = new PublicKey(raffleData.id)
      const raffleSettingsAccount = raffleSettings.raffleSettingsAccount
      const raffleStatsAccount = raffleSettings.raffleStatsAccount

      try{
      const [rafflePDA] = await PublicKey.findProgramAddress(
        [ RAFFLE_PDA_SEED, 
          raffleSettingsAccount.toBuffer(),
          raffleDataKey.toBuffer(),
        ],
        raffleProgramKey
      )
      showProgressToast(toastId, "Buying Raffle: Initiating the Txn...", false)
      const tx = solclub.transaction.enterOfficialRaffle(
        ticketsUsed,
        {
        accounts: {
          player: wallet.publicKey,
          raffleTokenAccount: tokenBalance.associatedAccount,
          raffleData: raffleDataKey,
          raffleSettingAccount: raffleSettingsAccount,
          raffleStatsAccount: raffleStatsAccount,
          raffleTokenVault: raffleData.onchainData.raffleTokenVault,
          raffleTokenMint: raffleData.onchainData.raffleTokenMint,
          rafflePdaAccount: rafflePDA,
          tokenProgram: TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        }
      })

      showProgressToast(toastId, "Buying Raffle: Waiting for Signature...")
      const txAll = new Transaction().add(tx);
          const signature = await wallet.sendTransaction(txAll, connection, {
              signers: [],
              skipPreflight: false,
              preflightCommitment: "confirmed",
            })

            showProgressToast(toastId, "Buying Raffle: Waiting for Confirmation of txn...")
            
            await getWalletBalances()
            await getAllRaffles()
             return successHandle(toastId, signature, "Transaction Confirmed, Raffle Ticket Bought! ✅", raffleData.id)
      return { result: true, error: null };
    } catch (err) {
      return errorHandle(toastId, err)
    }


     }


    const handleBuySolRaffle = async (raffleData, tickets, tokenBalance, toastId) => { 
      const ticketsUsed = parseInt(tickets)

      const raffleDataKey = new PublicKey(raffleData.id)
      const raffleSettingsAccount = raffleSettings.raffleSettingsAccount
      const raffleStatsAccount = raffleSettings.raffleStatsAccount

      try{
      const [rafflePDA] = await PublicKey.findProgramAddress(
        [ RAFFLE_PDA_SEED, 
          raffleSettingsAccount.toBuffer(),
          raffleDataKey.toBuffer(),
        ],
        raffleProgramKey
      )
      showProgressToast(toastId, "Buying Raffle: Initiating the Txn...", false)

      const tx = solclub.transaction.enterOfficialSolRaffle(
        ticketsUsed,
        {
        accounts: {
          player: wallet.publicKey,
          raffleTokenAccount: tokenBalance.associatedAccount,
          raffleData: raffleDataKey,
          raffleSettingAccount: raffleSettingsAccount,
          raffleStatsAccount: raffleStatsAccount,
          raffleTokenMint: raffleData.onchainData.raffleTokenMint,
          rafflePdaAccount: rafflePDA,
          raffleAuthority: authorityWallet,
          raffleFeeOwner: raffleFeeOwner,
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        }
      })
      showProgressToast(toastId, "Buying Raffle: Waiting for Signature...")
      const txAll = new Transaction().add(tx);
          const signature = await wallet.sendTransaction(txAll, connection, {
              signers: [],
              skipPreflight: false,
              preflightCommitment: "confirmed",
            })

            showProgressToast(toastId, "Buying Raffle: Waiting for Confirmation of txn...")
            
            await getWalletBalances()
            await getAllRaffles()
             return successHandle(toastId, signature, "Transaction Confirmed, Raffle Ticket Bought! ✅", raffleData.id)
    } catch (err) {
      return errorHandle(toastId, err)
    }


     }

     const handleWinnerClaim = async (raffleData, toastId) => {
      const raffleDataKey = new PublicKey(raffleData.id)
        const raffleSettingsAccount = raffleSettings.raffleSettingsAccount
        const raffleStatsAccount = raffleSettings.raffleStatsAccount

        let instructions = []
        showProgressToast(toastId, "Claiming Reward: Initiating the Txn...", false)
        try{
          const [rafflePDA] = await PublicKey.findProgramAddress(
            [ RAFFLE_PDA_SEED, 
              raffleSettingsAccount.toBuffer(),
              raffleDataKey.toBuffer(),
            ],
            raffleProgramKey
          )
  

        const winnerItemAccount = await getAssociatedTokenAddress(
          raffleData.onchainData.itemMint,
          wallet.publicKey
        )
    
        const itemAccountInfo = await connection.getAccountInfo(
          winnerItemAccount
        )

        if (itemAccountInfo == null) {
          let Ix = createAssociatedTokenAccountInstruction(
            wallet.publicKey,
            winnerItemAccount, 
            wallet.publicKey, 
            raffleData.onchainData.itemMint
          )
    
          instructions.push(Ix)
        }

        const tx = solclub.transaction.winnerWithdraw({
          accounts: {
            winner:  wallet.publicKey,
            winnerItemAccount: winnerItemAccount,
            raffleData: raffleDataKey,
            raffleSettingAccount: raffleSettingsAccount,
            raffleStatsAccount: raffleStatsAccount,
            itemVault: raffleData.onchainData.itemVault,
            rafflePdaAccount: rafflePDA,
            tokenProgram: TOKEN_PROGRAM_ID
          },
          signers: []
        })
        showProgressToast(toastId, "Claiming Reward: Waiting for Signature...")
        const txAll = new Transaction().add(...instructions, tx)
        const signature = await wallet.sendTransaction(txAll, connection, {
          signers: [],
          skipPreflight: false,
          preflightCommitment: "confirmed",
        })
        showProgressToast(toastId, "Claiming Reward: Waiting for Confirmation of txn...")
        return successHandle(toastId, signature, "Transaction Confirmed, Raffle Reward Withdrawn! ✅", raffleData.id)
      } catch (err) {
        return errorHandle(toastId, err)
      }
    }

    const handleWinnerOffchainClaim = async (raffleData, toastId) => {
      const raffleDataKey = new PublicKey(raffleData.id)
        const raffleSettingsAccount = raffleSettings.raffleSettingsAccount
        const raffleStatsAccount = raffleSettings.raffleStatsAccount

        showProgressToast(toastId, "Claiming Reward: Initiating the Txn...", false)
        try{
          const [rafflePDA] = await PublicKey.findProgramAddress(
            [ RAFFLE_PDA_SEED, 
              raffleSettingsAccount.toBuffer(),
              raffleDataKey.toBuffer(),
            ],
            raffleProgramKey
          )

        const tx = solclub.transaction.winnerOffchainWithdraw({
          accounts: {
            winner:  wallet.publicKey,
            raffleData: raffleDataKey,
            raffleSettingAccount: raffleSettingsAccount,
            raffleStatsAccount: raffleStatsAccount,
            rafflePdaAccount: rafflePDA,
            tokenProgram: TOKEN_PROGRAM_ID
          },
          signers: []
        })
        showProgressToast(toastId, "Claiming Reward: Waiting for Signature...")
        const txAll = new Transaction().add(tx)
        const signature = await wallet.sendTransaction(txAll, connection, {
          signers: [],
          skipPreflight: false,
          preflightCommitment: "confirmed",
        })
        showProgressToast(toastId, "Claiming Reward: Waiting for Confirmation of txn...")
        return successHandle(toastId, signature, "Transaction Confirmed, Raffle Reward Withdrawn! ✅", raffleData.id)
      } catch (err) {
        return errorHandle(toastId, err)
      }
    }



    const handleWithdrawTokens = async (raffleData, tokenBalance, toastId) => {
      const raffleDataKey = new PublicKey(raffleData.id)
        const raffleSettingsAccount = raffleSettings.raffleSettingsAccount
        const raffleStatsAccount = raffleSettings.raffleStatsAccount

        let instructions = []
        showProgressToast(toastId, "Claiming Tokens: Initiating the Txn...", false)
        try{
          const [rafflePDA] = await PublicKey.findProgramAddress(
            [ RAFFLE_PDA_SEED, 
              raffleSettingsAccount.toBuffer(),
              raffleDataKey.toBuffer(),
            ],
            raffleProgramKey
          )

          const tokenAccountInfo = await connection.getAccountInfo(tokenBalance.associatedAccount)

          if (tokenAccountInfo == null) {
            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              tokenBalance.associatedAccount, 
              wallet.publicKey, 
              raffleData.onchainData.raffleTokenMint
            )
      
            instructions.push(Ix)
          }
  
        const tx = solclub.transaction.withdrawTokens({
          accounts: {
            raffleAuthority:  wallet.publicKey,
            raffleAuthorityTokenAccount: tokenBalance.associatedAccount,
            raffleData: raffleDataKey,
            raffleSettingAccount: raffleSettingsAccount,
            raffleTokenVault: raffleData.onchainData.raffleTokenVault,
            raffleTokenMint: raffleData.onchainData.raffleTokenMint,
            rafflePdaAccount: rafflePDA,
            tokenProgram: TOKEN_PROGRAM_ID
          },
          signers: []
        })
        showProgressToast(toastId, "Claiming Token: Waiting for Signature...")
        const txAll = new Transaction().add(...instructions, tx)
        const signature = await wallet.sendTransaction(txAll, connection, {
          signers: [],
          skipPreflight: false,
          preflightCommitment: "confirmed",
        })
        showProgressToast(toastId, "Claiming Token: Waiting for Confirmation of txn...")
        return successHandle(toastId, signature, "Transaction Confirmed, Raffle Tokens Withdrawn! ✅", raffleData.id)
      } catch (err) {
        return errorHandle(toastId, err)
      }
    }

    const handleDeleteRaffle = async (id, toastId) => {
      const raffleDataKey = new PublicKey(id)

      try{
      showProgressToast(toastId, "Deleting Raffle: Initiating the Txn...", false)
      const tx = solclub.transaction.closeRaffleData({
        accounts:{
          raffleAuthority: wallet.publicKey,
          raffleData: raffleDataKey,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        }
      })
      showProgressToast(toastId, "Deleting Raffle: Waiting for Signature...")
        const txAll = new Transaction().add(tx)
        const signature = await wallet.sendTransaction(txAll, connection, {
          signers: [],
          skipPreflight: false,
          preflightCommitment: "confirmed",
        })
        showProgressToast(toastId, "Deleting Raffle: Waiting for Confirmation of txn...")
        return successHandle(toastId, signature, "Transaction Confirmed, Raffle Deleted! ✅", id)
      } catch (err) {
        return errorHandle(toastId, err)
      }
    }


    const handleDeleteWithdrawRaffle = async (id, toastId, itemMint, itemVault) => {
      console.log("delete")
      const raffleDataKey = new PublicKey(id)
      const raffleSettingsAccount = raffleSettings.raffleSettingsAccount
      const raffleStatsAccount = raffleSettings.raffleStatsAccount
      let instructions = []

      console.log(raffleDataKey.toString(), raffleSettingsAccount, raffleStatsAccount)
      try{
      const [rafflePDA] = await PublicKey.findProgramAddress(
        [ RAFFLE_PDA_SEED, 
          raffleSettingsAccount.toBuffer(),
          raffleDataKey.toBuffer(),
        ],
        raffleProgramKey
      )


      const authItemAccount = await getAssociatedTokenAddress(
        itemMint,
        wallet.publicKey
      )
  
      const itemAccountInfo = await connection.getAccountInfo(
        authItemAccount
      )

      if (itemAccountInfo == null) {
        let Ix = createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          authItemAccount, 
          wallet.publicKey, 
          itemMint
        )
  
        instructions.push(Ix)
      }

      showProgressToast(toastId, "Deleting Raffle: Initiating the Txn...", false)
      const tx = solclub.transaction.closeWithdrawRaffleData({
        accounts:{
          raffleAuthority: wallet.publicKey,
          raffleData: raffleDataKey,
          raffleSettingAccount:raffleSettingsAccount,
          raffleStatsAccount:raffleStatsAccount,
          itemVault:itemVault,
          itemAccount:authItemAccount,
          rafflePdaAccount:rafflePDA,
          tokenProgram:TOKEN_PROGRAM_ID,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        }
      })
      showProgressToast(toastId, "Deleting Raffle: Waiting for Signature...")
        const txAll = new Transaction().add(...instructions, tx)
        const signature = await wallet.sendTransaction(txAll, connection, {
          signers: [],
          skipPreflight: false,
          preflightCommitment: "confirmed",
        })
        showProgressToast(toastId, "Deleting Raffle: Waiting for Confirmation of txn...")
        return successHandle(toastId, signature, "Transaction Confirmed, Raffle Deleted! ✅", id)
      } catch (err) {
        return errorHandle(toastId, err)
      }
    }


    const handleCreateAuctionOfficial = async (auctionData, toastId) => {

      const isSolAuction = auctionData.isSol
      const isOnChain = auctionData.isOnChain ? 1 : 0
      const startPrice =  new anchor.BN(auctionData.startPrice * auctionData.tokenLamports)
      const stepPrice =  new anchor.BN(auctionData.stepPrice * auctionData.tokenLamports)
      const endDuration = new anchor.BN((auctionData.auctionEndTime.getTime() - auctionData.auctionStartTime.getTime())/60000)
      console.log(auctionData.startPrice * auctionData.tokenLamports, auctionData.stepPrice * auctionData.tokenLamports)
      const auctionTokenMintKey = auctionData.tokenKey !== "" ? new PublicKey(auctionData.tokenKey) : new PublicKey(defaultToken.mintKey)

      const instructions = []
      showProgressToast(toastId, "Initiating Auction: Gathering all accounts...", false)

      const auctionDataAccount = anchor.web3.Keypair.generate()

      try{
      let [auctionPda, auctionPdaNonce] = await anchor.web3.PublicKey.findProgramAddress(
        [
          AUCTION_PDA_SEED,
          wallet.publicKey.toBuffer(),
          auctionDataAccount.publicKey.toBuffer(),
        ],
        solclub.programId,
      );

      let remainingAccounts = []

      if(isOnChain === 1){
        const rewardMintKey = new PublicKey(auctionData.itemMint)
        const authorityItemAccount = await getAssociatedTokenAddress(
          rewardMintKey,
          wallet.publicKey
        )

          //Reward item vault on chain
          const itemVault = await getAssociatedTokenAddress(
            rewardMintKey,
            auctionPda,
            true
          )
      
          const itemVaultInfo = await connection.getAccountInfo(
            itemVault
          )
      
          if (itemVaultInfo == null) {
            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              itemVault,
              auctionPda,
              rewardMintKey
            )
      
            instructions.push(Ix)
         }

        remainingAccounts = [
          {
            pubkey: authorityItemAccount,
            isSigner: false,
            isWritable: true,
          },
          {
            pubkey: rewardMintKey,
            isSigner: false,
            isWritable: false,
          },
          {
            pubkey: itemVault,
            isSigner: false,
            isWritable: true,
          }
        ]
      }

      //Auction token vault
      const auctionTokenVault = await getAssociatedTokenAddress(
        auctionTokenMintKey,
        auctionPda,
        true
      )
      const auctionTokenVaultInfo = await connection.getAccountInfo(
        auctionTokenVault
      )
  
      if (auctionTokenVaultInfo == null) {
        let Ix = createAssociatedTokenAccountInstruction(
          wallet.publicKey,
          auctionTokenVault,
          auctionPda,
          auctionTokenMintKey
        )
        instructions.push(Ix)
      }

      showProgressToast(toastId, "Creating Auction: Initiating the Txn...")


      const tx = solclub.transaction.createAuction(
        startPrice,
        endDuration,
        isSolAuction,
        isOnChain,
        stepPrice,
        {
          accounts: {
            auctionData: auctionDataAccount.publicKey,
            raffleStatsAccount: raffleSettings.raffleStatsAccount,
            auctionAuthority:wallet.publicKey,
            auctionPdaAccount:auctionPda,
            auctionTokenVault: auctionTokenVault,
            auctionTokenMint:auctionTokenMintKey,
            systemProgram: SystemProgram.programId,
            tokenProgram: TOKEN_PROGRAM_ID,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY
          },
          remainingAccounts: remainingAccounts,
          signers: [
            auctionDataAccount
          ]
        }
      )

      showProgressToast(toastId, "Creating Auction: Waiting for Signature...")

      const txAll = new Transaction().add(...instructions, tx);
      
      showProgressToast(toastId, "Creating Auction: Waiting for Confirmation of txn...")

      const signature = await wallet.sendTransaction(txAll, connection, {
        signers: [auctionDataAccount],
        skipPreflight: false,
        preflightCommitment: "confirmed",
      })
      setTxnId(signature)

      return successHandle(toastId, signature, "Transaction Confirmed, Auction Created! ✅", auctionDataAccount.publicKey.toString(), false)
   // await getAllRaffles()
      } catch (err) {
        return errorHandleExtra(toastId, err, auctionDataAccount.publicKey.toString())
      }

    }



    const handleBidAuction = async (auctionData, bidPrice, tokenBalance, toastId) => { 
      const price = new anchor.BN(bidPrice * auctionData.token.lamportsPerToken)
      const auctionDataKey = new PublicKey(auctionData.id)
      const authority = auctionData.onchainData.auctionAuthority

      console.log(new Date().getTime())
      console.log(auctionData.onchainData.endTs.toNumber() * 1000)
      try{
        let [auctionPda, auctionPdaNonce] = await anchor.web3.PublicKey.findProgramAddress(
          [
            AUCTION_PDA_SEED,
            authority.toBuffer(),
            auctionDataKey.toBuffer(),
          ],
          solclub.programId,
        );

        console.log(tokenBalance)
        console.log({
          auctionData: auctionDataKey.toString(),
          bidder: wallet.publicKey.toString(),
          bidderTokenAccount: auctionData.isSol ? defaultToken.associatedAccount.toString() : tokenBalance.associatedAccount.toString(),
          auctionTokenVault: auctionData.onchainData.auctionTokenVault.toString(),
          auctionPdaAccount: auctionPda.toString(),
          previousBidderTokenAccount: auctionData.onchainData.refundReceiver.toString(),
          previousBidder: auctionData.onchainData.bidder.toString(),
          tokenProgram: TOKEN_PROGRAM_ID,
          systemProgram: SystemProgram.programId,
          clock: anchor.web3.SYSVAR_CLOCK_PUBKEY
        })

      showProgressToast(toastId, "Bidding Auction: Initiating the Txn...", false)
  const tx = solclub.transaction.bidAuction(
    price,
    {
      accounts: {
        auctionData: auctionDataKey,
        bidder: wallet.publicKey,
        bidderTokenAccount: auctionData.isSol ? defaultToken.associatedAccount : tokenBalance.associatedAccount,
        auctionTokenVault: auctionData.onchainData.auctionTokenVault,
        auctionPdaAccount: auctionPda,
        previousBidderTokenAccount: auctionData.onchainData.refundReceiver,
        previousBidder: auctionData.onchainData.bidder,
        tokenProgram: TOKEN_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY
      }
    }
  )

      showProgressToast(toastId, "Bidding Auction: Waiting for Signature...")
      const txAll = new Transaction().add(tx);
          const signature = await wallet.sendTransaction(txAll, connection, {
              signers: [],
              skipPreflight: false,
              preflightCommitment: "confirmed",
            })

            showProgressToast(toastId, "Bidding Auction: Waiting for Confirmation of txn...")
            
            await getWalletBalances()
            await getAllRaffles()
             return successHandle(toastId, signature, "Transaction Confirmed, Auction Bid Placed! ✅", auctionData.id)
    } catch (err) {
      console.log(err)
      return errorHandle(toastId, err)
    }

     }





    const handleAuctionClaim = async (auctionData, toastId) => { 
      const auctionDataKey = new PublicKey(auctionData.id)
      const isOnChain = auctionData.isOnChain ? 1 : 0
      const auctionTokenMintKey = new PublicKey(auctionData.isSol ? defaultToken.mintKey : auctionData.tokenKey)
      const authority = auctionData.onchainData.auctionAuthority
      showProgressToast(toastId, "Initiating Claim Auction: Gathering all accounts...", false)
      try{

        let [auctionPda, auctionPdaNonce] = await anchor.web3.PublicKey.findProgramAddress(
          [
            AUCTION_PDA_SEED,
            authority.toBuffer(),
            auctionDataKey.toBuffer(),
          ],
          solclub.programId,
        );

        let instructions = []

        const authorityTokenAccount = await getAssociatedTokenAddress(
          auctionTokenMintKey,
          authority
        )
        
        const authorityTokenAccountInfo = await connection.getAccountInfo(
          authorityTokenAccount
          )
      
          if (authorityTokenAccountInfo == null) {

            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              authorityTokenAccount,
              authority,
              auctionTokenMintKey
            )
            instructions.push(Ix)

          }


        let remainingAccounts = []


        if(isOnChain === 1){

          let itemVault = new PublicKey(auctionData.onchainData.itemVault)
          let itemMint = new PublicKey(auctionData.onchainData.itemMint)

          let winnerBidderItemAccount = await getAssociatedTokenAddress(
            itemMint,
            wallet.publicKey
          )

          const winnerBidderItemAccountInfo = await connection.getAccountInfo(
            winnerBidderItemAccount
          )
      
          if (winnerBidderItemAccountInfo == null) {
            let Ix = createAssociatedTokenAccountInstruction(
              wallet.publicKey,
              winnerBidderItemAccount,
              wallet.publicKey,
              itemMint
            )
            instructions.push(Ix)
          }

          remainingAccounts = [
            {
              pubkey: itemVault,
              isSigner: false,
              isWritable: true,
            },
            {
              pubkey: winnerBidderItemAccount,
              isSigner: false,
              isWritable: true,
            }
          ]
        }


        showProgressToast(toastId, "Claiming Auction: Initiating the Txn...")

        const tx = solclub.transaction.closeAuction(
          {
          accounts: {
            auctionData: auctionDataKey,
            winningApprover: wallet.publicKey,
            winningBidder: wallet.publicKey,
            auctionTokenVault: auctionData.onchainData.auctionTokenVault,
            auctionTokenAuthorityAccount: authorityTokenAccount,
            auctionAuthority: authority,
            auctionPdaAccount: auctionPda,
            tokenProgram: TOKEN_PROGRAM_ID,
            systemProgram: SystemProgram.programId,
            clock: anchor.web3.SYSVAR_CLOCK_PUBKEY
          },
          remainingAccounts: remainingAccounts,
        }
      )

      showProgressToast(toastId, "Claiming Auction: Waiting for Signature...")

      const txAll = new Transaction().add(...instructions, tx);
      
      showProgressToast(toastId, "Claiming Auction: Waiting for Confirmation of txn...")

      const signature = await wallet.sendTransaction(txAll, connection, {
        signers: [],
        skipPreflight: false,
        preflightCommitment: "confirmed",
      })
      setTxnId(signature)

      return successHandle(toastId, signature, "Transaction Confirmed, Auction Created! ✅", auctionDataKey.toString())
   // await getAllRaffles()
      } catch (err) {
        console.log(err)
        return errorHandleExtra(toastId, err, auctionDataKey.toString())
      }



     }

    //extra functions

    const successHandle = async (toastId, signature, successMsg, account, finish = true) => {
          const result = await confirmTransactionWithRetry(connection, signature)
            if(!result.error){
              finish ? showProgressToast(toastId, successMsg, true, "success") : showProgressToast(toastId, successMsg, true) 
              return { result: true, account: account, txid: signature, error: null };
            }else{
              showProgressToast(toastId, `${result.error} ❌`, true, "error")
              return { result: false, account: account, txid: signature, error: result.error };
            }
    }


    const errorHandle = (toastId, err) => { 
      const msg = err.message ? err.message :  err.msg ?  err.msg : err.toString()
      const error = getErrorCode(msg);
      if (error) {
        showProgressToast(toastId, `${error} ❌`, true, "error")
        return { result: false, error: error }
      } else {
        showProgressToast(toastId, `${err} ❌`, true, "error")
        return { result: false, error: err }
      }
   }

   

     const errorHandleExtra = (toastId, err, account) => { 
      const msg = err.message ? err.message :  err.msg ?  err.msg : err.toString()
      const error = getErrorCode(msg);
      console.log(error, err)
      if(error){
        showProgressToast(toastId, `${error} ❌`, true, "error")
        return { result: false, account: account, txid:txnId, error: error };
      }
      else{
        showProgressToast(toastId, `${err} ❌`, true, "error")
        return { result: false, account: account, txid:txnId, error: `Unknown Error: ${err}`};
      }
    }




    const getErrorCode = (msg) => {
      if(msg.includes("in 60.00 seconds") || msg.includes("in 30.00 seconds")){
        return "Transaction was not confirmed within 60secs, Solana Network is slow. Please refresh page or check your latest transactions on Solscan to check if the txn succeeded"
      }
      
      const code = msg.includes("0x") ? msg.split("0x")[1].split(" ")[0] : msg;
  
      switch (code) {
        case "1770":
          return "Wrong PDA owner sent.";
        case "1771":
          return "Raffle already finished.";
        case "1772":
          return "Raffle Not finished yet.";
        case "1773":
          return "Not Possible to select Winner with winners more than one";
        case "1774":
          return "Wrong Raffle Type!";
        case "1775":
          return "Insufficient Raffle ticket Balance!";
        case "1776":
          return "Wrong Winner Selection Size!";
        case "1777":
          return "Insufficient Winning Item Balance!";
        case "1778":
          return "You are not the Winner of this Raffle!";
        case "1779":
          return "Reward Already Claimed!";
        case "177a":
          return "Not enough entries available for the Raffle!";
          case "177b":
            return "Wrong Raffle Mint Type!";
            case "177c":
                return "Raffle Reward not claimed yet!";
        default:
          return msg;
      }
    };


    const sleep = async (ms) => {
      return new Promise((r) => setTimeout(r, ms));
    };

  return (
    <ClubBaseContext.Provider value={{
      isProgramLoaded:isProgramLoaded,
      allRaffles:allRaffles,
      allAuctions:allAuctions,
      raffleSettings:raffleSettings,
      raffleStats:raffleStats,
      getSingleRaffles:getSingleRaffles,
      getSingleAuctions:getSingleAuctions,
      getRaffleTransactions:getRaffleTransactions,
      getAuctionTransactions:getAuctionTransactions,
      handleCreateRaffleOfficial:handleCreateRaffleOfficial,
      handleCreateOfflineRaffleOfficial:handleCreateOfflineRaffleOfficial,
      handleBuyRaffle:handleBuyRaffle,
      handleBuySolRaffle:handleBuySolRaffle,
      handleWinnerClaim:handleWinnerClaim,
      handleWinnerOffchainClaim:handleWinnerOffchainClaim,
      handleWithdrawTokens:handleWithdrawTokens,
      handleDeleteRaffle:handleDeleteRaffle,
      handleDeleteWithdrawRaffle:handleDeleteWithdrawRaffle,
      handleCreateAuctionOfficial:handleCreateAuctionOfficial,
      handleBidAuction:handleBidAuction,
      handleAuctionClaim:handleAuctionClaim
    }}>
        {children}
    </ClubBaseContext.Provider>
  )
}

export const useClubBase = () => useContext(ClubBaseContext)