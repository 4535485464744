import React, { createContext, useEffect, useState } from 'react'

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

import { getDoc, doc, setDoc } from "firebase/firestore"; 

import { useWallet } from '@solana/wallet-adapter-react'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

export const FirebaseContext = createContext()

export const firebaseConfig = {
    apiKey: process.env.NEXT_PUBLIC_API_KEY,
    authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
    projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
    storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
    databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
    messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
    appId: process.env.NEXT_PUBLIC_APP_ID,
  }

export default function FirebaseProvider({children}) {
    const [isAdmin, setIsAdmin] = useState(false)
    const {publicKey} = useWallet()
    const router = useRouter();

    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    

    // firebase.initializeApp(firebaseConfig)

    // const auth = firebase.auth()
    // const firestore = firebase.firestore()

    const { projectData } = useSelector((state) => state.counter);

    useEffect(() => {
      ;(async()=>{
        if(isAdmin && projectData)
        await getProjectData()
      })()
    }, [publicKey, projectData])
     

    const getProjectData = async () => { 
      console.log(projectData.programKey)
      const projectRef = doc(db,"projects", projectData.programKey)
      const projSnap = await getDoc(projectRef)
        // const projSnap = await firestore.collection("projects").doc(projectData.programKey).get()
        
        if(publicKey && projSnap.data().raffleAuthority === publicKey.toString()){
          console.log("isAsmin")
            setIsAdmin(true)
        } else {
          console.log("router pushing")
            setIsAdmin(false)
            router.push(`/${projectData.slug.current}`)
        }
        // console.log(projSnap.data())
     }


     const setRaffle = async (data) => { 
      const raffleRef = doc(db, `raffles/${data.raffleData}`)
      await setDoc(raffleRef, data, { merge: true })
    }

  return (
    <FirebaseContext.Provider value={{
        app:app,
        isAdmin:isAdmin,
        getProjectData:getProjectData,
        setRaffle:setRaffle
    }}>
        {children}
        </FirebaseContext.Provider>
  )
}
