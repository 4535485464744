import { ThemeProvider } from "next-themes"
import { Provider } from "react-redux"
import { store } from "../redux/store"
import { Router } from "next/router"
import Meta from "../components/Meta"
import { useMemo } from "react"
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react"
import { SessionProvider } from "next-auth/react"
import NProgress from "nprogress"
import "@solana/wallet-adapter-react-ui/styles.css"
import "../styles/globals.css"
import "react-toastify/dist/ReactToastify.css"
import "../styles/datepicker.css"
import { QueryClient, QueryClientProvider } from "react-query"
//import "react-datepicker/dist/react-datepicker.css";

import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  SolletWalletAdapter,
  TorusWalletAdapter,
} from "@solana/wallet-adapter-wallets"
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base"
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"
import FirebaseProvider from "../contexts/FirebaseProvider"
import BaseProvider from "../contexts/BaseProvider"
import ClubBaseProvider from "../contexts/ClubBaseProvider"

function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  NProgress.configure({ showSpinner: false })

  Router.events.on("routeChangeStart", (url) => {
    NProgress.start()
  })

  Router.events.on("routeChangeComplete", (url) => {
    NProgress.done()
  })

  let network
  if (process.env.NODE_ENV === "production") {
    network = WalletAdapterNetwork.Mainnet
  } else {
    network = WalletAdapterNetwork.Devnet
  }
  const endpoint = useMemo(
    () => process.env.NEXT_PUBLIC_RPC_NODE,
    [process.env.NEXT_PUBLIC_RPC_NODE]
  )

  const config = {
    confirmTransactionInitialTimeout: 60000,
    commitment: "confirmed",
  }

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter({ network }),
      new TorusWalletAdapter(),
      new LedgerWalletAdapter(),
      new SolletWalletAdapter({ network }),
    ],
    [network]
  )

  const queryClient = new QueryClient()

  return (
    <>
      <Meta title="Home || SolClub | OneStop Token Utility" />
      <SessionProvider session={session}>
        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="data-theme">
            <ConnectionProvider endpoint={endpoint} config={config}>
              <WalletProvider wallets={wallets} autoConnect>
                <WalletModalProvider>
                  <BaseProvider>
                    <FirebaseProvider>
                      <ClubBaseProvider>
                        <QueryClientProvider client={queryClient}>
                          <Component {...pageProps} />
                        </QueryClientProvider>
                      </ClubBaseProvider>
                    </FirebaseProvider>
                  </BaseProvider>
                </WalletModalProvider>
              </WalletProvider>
            </ConnectionProvider>
          </ThemeProvider>
        </Provider>
      </SessionProvider>
    </>
  )
}

export default MyApp
