import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import React, { createContext, useEffect, useRef, useState } from 'react'
import {getAssociatedTokenAddress} from "@solana/spl-token"
import TokenData from '../data/token_data';
import { PublicKey } from '@solana/web3.js';


export const BaseContext = createContext()

export default function BaseProvider({children}) {

const {connection} = useConnection()
const {publicKey} = useWallet()
const [walletBalance, setwalletBalance] = useState(null)
const [toastRef, setToastRef] = useState(null)

const scrollRef = useRef({
  scrollPos: 0,
})


useEffect(() => {
    ;(async ()=> {
        if(publicKey){
            await getWalletBalances()
        }
    })()

}, [publicKey])



const getWalletBalances = async () => { 
    let tokenListBal = TokenData
    for (let i = 0; i < tokenListBal.length; i++) {
        if(tokenListBal[i].symbol === "SOL"){
           const solBal = await connection.getBalance(publicKey)
           tokenListBal[i].balance = solBal/tokenListBal[i].lamportsPerToken
        }else{
            let bal = 0
            let assKey = await getAssociatedTokenAddress(
                new PublicKey(tokenListBal[i].mintKey),
                publicKey
              )
              console.log("Running get")
              const assAccountInfo = await connection.getAccountInfo(assKey)
              if (assAccountInfo != null) {
                const balData = await connection.getTokenAccountBalance(assKey)
                bal = balData.value.amount / tokenListBal[i].lamportsPerToken
              }
              tokenListBal[i].balance = bal
              tokenListBal[i].associatedAccount = assKey
        }
    }
    setwalletBalance(tokenListBal)
 }

 const showToast = (msgType, msg, icon="nc-icon nc-bell-55", timeout=5) => {
  var options = {};
  var type;
  switch (msgType) {
    case "success":
      type = "success";
      break;
    case "error":
      type = "danger";
      break;
    case "info":
      type = "warning";
      break;
    default:
      break;
  }
  options = {
    place: 'br',
    message: (
      <div>
        <div>
          {msg}
        </div>
      </div>
    ),
    type: type,
    icon: icon,
    autoDismiss: timeout,
  };
  toastRef.current.notificationAlert(options);
};

  return (
    <BaseContext.Provider value={{
      scrollRef:scrollRef,
      toastRef:toastRef,
      setToastRef:setToastRef,
      walletBalance:walletBalance,
      getWalletBalances:getWalletBalances
    }}> 
        {children}
    </BaseContext.Provider>
  )
}


