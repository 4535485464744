export const confirmTransactionWithRetry = async (connection, txId) => { 
    const statusCheckInterval = 300;
    const timeout = 60000;
    let isBlockhashValid = true;

    const inititalBlock = (await connection.getSignatureStatus(txId)).context.slot;
    let done = false;
    let isTimedOut = false;
    setTimeout(() => {
        if (done) {
          return {status: "warning", error: "timeout"};
        }
        done = true;
        console.log('Timed out for txid', txId);
        console.log(`${isBlockhashValid ? 'Blockhash not yet expired.' : 'Blockhash has expired.' }`)
        isTimedOut = true
        return {status: "warning", error: "timeout"};
        //return isBlockhashValid ? {status: "warning", error: "Txn Timed out, but the txn may still be processing, check the txn on solscan to confirm!"} : {status: "warning", error: "Txn timed out! Initiate the txn again."}
    }, timeout);

    while (!done && isBlockhashValid) {
        const confirmation = await connection.getSignatureStatus(txId);

        if(confirmation.value && confirmation.value.err){
            done = true;
            return {status: "error", error: "Unknown txn error"}
        }

        if (confirmation.value && ((confirmation.value.confirmationStatus === 'confirmed') || (confirmation.value.confirmationStatus === 'finalized'))) {
            //console.log(`Confirmation Status: ${confirmation.value.confirmationStatus}`, txId)
            done = true;
            return {status: "success", error: null}
        }
        else {
            console.log(`Confirmation Status: ${confirmation.value?.confirmationStatus || 'not yet found.'}`);
        }
        isBlockhashValid =  !(await isBlockhashExpired(inititalBlock, connection));
        await sleep(statusCheckInterval);
    }

    if(done && isTimedOut){
        return {status: "warning", error: "timeout"};
    }
}

const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
async function isBlockhashExpired(initialBlockHeight, connection) {
    let currentBlockHeight = (await connection.getBlockHeight());
    return (currentBlockHeight > initialBlockHeight);
}