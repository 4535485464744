
import { toast } from "react-toastify"
import Loader from "../components/other/Loader"


export const showToast = (msg, type) => {
    toast[type](msg, {
      position: "bottom-right",
      toastId: 'login-notification',
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    })
  }



export const showProgressToast = (toastId, msg, update=true, status='update') => {
    if(!update){
      toastId.current = toast(() => <div className="flex items-center gap-2"><Loader/><span>{msg}</span></div>
      , { autoClose: false, position: "bottom-right" });
    }
    else if(status == "update"){
      toast.update(toastId.current, {
        render: () => <div className="flex items-center gap-2"><Loader/><span>{msg}</span></div>,
        position: "bottom-right",
      });
    }else if(status == "success"){
      toast.update(toastId.current, {
        render: msg,
        type: toast.TYPE.SUCCESS,
        autoClose: 1000,
        position: "bottom-right",
      });
    }else if(status == "error"){
      toast.update(toastId.current, {
        render: msg,
        type: toast.TYPE.ERROR,
        autoClose: 3000,
        position: "bottom-right",
      });
    }else{
      toast.update(toastId.current, {
        render: msg,
        type: toast.TYPE.WARNING,
        autoClose: 3000,
        position: "bottom-right",
      });
    }
  }
