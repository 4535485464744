const MainnetTokenData = [
     {
		id: 0,
		icon: '/images/tokens/solana.svg',
		name: 'Solana',
		symbol: 'SOL',
        mintKey: "So11111111111111111111111111111111111111112",
        lamportsPerToken: 1000000000,
        balance: 0
	    },
	 {
		id: 1,
		icon: '/images/tokens/rudetoken.png',
		name: 'Rude Token',
		symbol: 'RUDE',
        mintKey: "RUDEx2bKtRsZmxXS5cGNVLXbX4u5ECynCbTjfNuRbsZ",
		lamportsPerToken: 1000000000,
        balance: 0
	    },
     {
		id: 2,
		icon: '/images/tokens/rogue-logo.svg',
		name: 'ROGUE Token',
		symbol: 'ROGUE',
        mintKey: "rogue3qZEABqmjpL9orMosdBh2a1rfmpoDKfPrztG1K",
        lamportsPerToken: 10000000,
        balance: 0
    },
	{
		id: 3,
		icon: '/images/tokens/botc-logo.png',
		name: 'Botcoin',
		symbol: 'BOTC',
        mintKey: "botcSYmVPr3KvyJurMMxDqRfxszjKpKvGJJwjNhXm2x",
        lamportsPerToken: 10000000,
        balance: 0
    }
]

const DevnetTokenData = [
	{
	   id: 0,
	   icon: '/images/tokens/solana.svg',
	   name: 'Solana',
	   symbol: 'SOL',
	   mintKey: "So11111111111111111111111111111111111111112",
	   lamportsPerToken: 1000000000,
	   balance: 0
	   },
	{
	   id: 1,
	   icon: '/images/tokens/rudetoken.png',
	   name: 'Rude Token',
	   symbol: 'RUDE',
	   mintKey: "FpeYZivvGVB2agJUnShLkyuryG6o4tdKkvQ5oTBXB4my",
	   lamportsPerToken: 1000000000,
	   balance: 0
	   },
]

const TokenData = process.env.NEXT_PUBLIC_PROJECT_ID === "solclub-prod" ? MainnetTokenData : DevnetTokenData
export default TokenData;